import {Injectable} from '@angular/core';
import {HealthDocumentApiService} from '../../../../../core/api-services/health-document/health-document.api.service';
import {DataSetElementHealthDocumentApiService} from '../../../../../core/api-services/data-set-element/health-document/data-set-element-health-document.api.service';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {PatientHealthDocumentApiService} from '../../../../../core/api-services/patient/health-document/patient-health-document.api.service';

@Injectable({
    providedIn: 'root'
})
export class StayDetailDocumentElementService {
    private _FORBIDDEN_WORDS: string[] = [];

    constructor(private _healthDocumentApiService: HealthDocumentApiService,
                private _dataSetElementHealthDocumentApiService: DataSetElementHealthDocumentApiService,
                private _patientHealthDocumentApiService: PatientHealthDocumentApiService,
                private _configurationService: ConfigurationService) {
        this._FORBIDDEN_WORDS = this._initForbiddenWords(this._configurationService.getConfigurationContent('front', 'justification.forbiddenWords'));
    }

    private _initForbiddenWords(wordsAsString: string) {
        return wordsAsString ? wordsAsString.trim().split(',').map(el => el.trim()) : [];
    }

    async loadHealthDocumentLines(document: any, params: any) {
        try {
            params = {
                ...params,
                include: 'healthDocumentContent'
            };
            if (document &&
                document.documentType &&
                document.documentType.structuredData) {
                params.include += ',structuredData';
            }
            return await this._healthDocumentApiService
                .get(document.id, params)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async summarizeHealthDocument(documentId: number) {
        try {
            return await this._healthDocumentApiService
                .summarize(documentId)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async attachOrDetachHealthDocumentToStay(dataSetElementId: number,
                                             healthDocumentId: number,
                                             useCase: string) {
        try {
            const body = {fields: 'documentType,parent,inferredDocumentType'};
            if (useCase === 'attach') {
                return await this._dataSetElementHealthDocumentApiService
                    .attach(dataSetElementId, healthDocumentId, body)
                    .toPromise();
            } else {
                return await this._dataSetElementHealthDocumentApiService
                    .detach(dataSetElementId, healthDocumentId, body)
                    .toPromise();
            }
        } catch (e) {
            throw e;
        }
    }

    async attachOrDetachHealthDocumentToPatient(patientId: number,
                                                healthDocumentId: number,
                                                dataSetElementId: number,
                                                useCase: string) {
        try {
            const body = {fields: 'documentType,parent,inferredDocumentType', dataSetElementId};
            if (useCase === 'attach') {
                return await this._patientHealthDocumentApiService
                    .attach(patientId, healthDocumentId, body)
                    .toPromise();
            } else {
                return await this._patientHealthDocumentApiService
                    .detach(patientId, healthDocumentId, body)
                    .toPromise();
            }
        } catch (e) {
            throw e;
        }
    }

    transformHealthDocumentContentText(document: any) {
        if (document &&
            document.healthDocumentContent &&
            document.healthDocumentContent.text) {
            if (document.healthDocumentContent.text.includes('<medication')) {
                document.healthDocumentContent.text =
                    document.healthDocumentContent.text.replace(/medication/g, 'ct-medication');
            }

            if (!document.healthDocumentContent.text.includes('<table') &&
                !document.healthDocumentContent.text.includes('<ct-medication')) {
                document.healthDocumentContent.textModified =
                    document.healthDocumentContent.text.replace(/\s/g, '&nbsp;');
            } else {
                document.healthDocumentContent.textModified =
                    document.healthDocumentContent.text;
            }
        }
    }

    /**
     * For the case we change current filterSearch we need to reset
     * previous document highlights
     * @private
     */
    private _removePreviousHighlight(document: any) {
        const reg = new RegExp(`<em>|</em>`);
        if (document &&
            document.healthDocumentContent &&
            document.healthDocumentContent.textModified) {
            const matches = document.healthDocumentContent.textModified.match(reg);
            if (matches !== null) {
                document.healthDocumentContent.textModified =
                    document.healthDocumentContent.textModified
                        .replace(/<em>/g, '')
                        .replace(/<\/em>/g, '');
                document.isOpened = false;
            }
        }
    }

    private _isForbiddenWord(word: string) {
        return !!(word && this._FORBIDDEN_WORDS.includes(word.toLowerCase()));
    }

    private _highlightDocumentText(document: any, text: string) {
        if (text &&
            document?.healthDocumentContent?.textModified &&
            document?.healthDocumentContent?.text) {
            // Expression at beginning/end of string or not inside a word or preceded/followed by whitespace or preceded by/followed by special characters
            const reg = new RegExp(`(^|\\s|[,;.*#\\-+_()&:\/?%$€><"'=~{}|\\\\])(${text})($|\\s|[,;.*#\\-+_()&:\\/?%$€><"=~{}|\\\\])`, 'g');
            const matchesIterator: IterableIterator<RegExpMatchArray> = document.healthDocumentContent.text.matchAll(reg) || [];
            Array.from(matchesIterator).forEach(matches => {
                if (matches !== null &&
                    !this._isForbiddenWord(matches[2])) {
                    // Need space before <em>
                    document.healthDocumentContent.textModified =
                        document.healthDocumentContent.textModified
                            .replace(matches[0], `${matches[1]}<em>${matches[2]}</em>${matches[3]}`);
                    document.isOpened = true;
                    document.activeTabId = 1;
                }
            });
        }
    }

    /**
     * Add highlight in document lines
     * @param data
     * @param document
     * @private
     */
    highLightTextInDocument(data: any, document: any) {
        if (data &&
            document) {
            if ((data.healthDocumentId === -1 ||
                    (data.healthDocumentId && data.healthDocumentId.includes(document.id))) &&
                !document.documentType.structuredData &&
                document.healthDocumentContent &&
                data.highlightList &&
                (data.qDocumentTypeId &&
                    (data.qDocumentTypeId.includes(document.documentTypeId.toString()) ||
                        data.qDocumentTypeId.includes('-1') ||
                        data.qDocumentTypeId.length === 0))) {
                document.isOpened = false;
                document.healthDocumentContent.textModified = document.healthDocumentContent.text;
                data.highlightList.forEach(element => {
                    if (element.structuredData === false) {
                        this._highlightDocumentText(document, element.text);
                    }
                });
            } else if (document.documentType.structuredData) {
                if (data.highlightList &&
                    data.highlightList.length) {
                    data.highlightList.forEach(element => {
                        document.isOpened = element.structuredData === true && element.documentTypeId === document.documentTypeId;
                    });
                } else {
                    document.isOpened = false;
                }
            } else if (document.isOpened) {
                this._removePreviousHighlight(document);
            }
        }
    }
}

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'ct-search-engine-condition-number-comparison',
    templateUrl: './search-engine-condition-number-comparison.component.html',
    styleUrls: ['./search-engine-condition-number-comparison.component.scss']
})
export class SearchEngineConditionNumberComparisonComponent implements OnInit {
    condition: any;
    paramMinKey: string;
    paramMaxKey: string;
    unit: string;

    constructor() { }

    ngOnInit(): void {
    }

}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DataSetElement} from '../../shared/data-set-element.model';
import * as _ from 'lodash-es';
import {DiagnosticService} from '../../../diagnostic/diagnostic.service';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {Subscription} from 'rxjs';
import {StayDetailJustificationService} from '../justification/stay-detail-justification.service';
import {StayDetailCodificationDiagnosticService} from '../codification/diagnostic/stay-detail-codification-diagnostic.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslationHelperService} from '../../../../core/services/translation.helper.service';
import {DiagnosticNoteService} from '../../../diagnostic/note/diagnostic-note.service';
import {StateService} from '@uirouter/angular';
import {CodificationLabelsModel} from "../../shared/codification-labels.model";

@Component({
    selector: 'ct-stay-detail-imprecise-diagnostic',
    templateUrl: './stay-detail-imprecise-diagnostic.component.html',
    styleUrls: ['./stay-detail-imprecise-diagnostic.component.scss']
})
export class StayDetailImpreciseDiagnosticComponent implements OnInit, OnDestroy {
    private _subscriptions: Subscription[] = [];

    @Input() dataSetElement: DataSetElement;
    @Input() diagnostics: any[];
    @Input() isTypeRehabilitation: boolean;
    @Input() codificationLabels: CodificationLabelsModel;

    impreciseDiagnostics: any[] = [];
    preciseDiagnostics: any[] = [];
    canDisplaySeverityLevel: boolean;
    selectedImpreciseDiagnostic: any;
    isActive: boolean;
    isLoading: boolean;

    constructor(public $state: StateService,
                private _translateService: TranslateService,
                private _translationHelperService: TranslationHelperService,
                private _diagnosticService: DiagnosticService,
                private _diagnosticNoteService: DiagnosticNoteService,
                private _stayDetailJustificationService: StayDetailJustificationService,
                private _stayDetailCodificationDiagnosticService: StayDetailCodificationDiagnosticService,
                private _broadcastService: BroadcastService) { }

    ngOnInit() {
        this._initAvailableFeatures();
        this._filterDiagnostics();
        this._subscribeToBroadcast();
        this.isActive = true;
    }

    private _subscribeToBroadcast() {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'stayDetailJustification::initDone':
                        // if reloadOnlyFirstTab is true then it means it's not the first page load
                        // so we don't want to reload precise diagnostics
                        if (res.data &&
                            !res.data.reloadOnlyFirstTab &&
                            this.$state.params.fromQualityControlImpreciseDiagnostic === 'true' &&
                            this.impreciseDiagnostics.length > 0) {
                            this._loadPreciseDiagnosticsAndShowJustification(this.impreciseDiagnostics[0]);
                        }
                        break;
                    case 'diagnostic::addDiagnostic':
                        if (res.data &&
                            res.data.success) {
                            this._filterDiagnostics();
                        }
                        break;
                    case 'diagnostic::editDiagnostic':
                        if (res.data &&
                            res.data.success &&
                            res.data.diagnosticToRemove) {
                            this.selectedImpreciseDiagnostic = null;
                            this._deleteImpreciseDiagnostic(res.data.diagnosticToRemove);
                            this._broadcastService.send('stayDetailImpreciseDiagnostic::deleteDiagnostic', {name: res.data.diagnosticToRemove.slug});
                        }
                        break;
                    case 'stayDetailJustification::search':
                        // If we click on any justification tab
                        if (res.data &&
                            res.data.tab) {
                            this._handleJustificationSwitchTab(res.data.tab);
                        }
                        break;
                }
            });
        this._subscriptions.push(sub);
    }

    private _initAvailableFeatures() {
        this.canDisplaySeverityLevel = this._translationHelperService.isFeatureAvailable('diagnosticSeverityLevel');
    }

    private _handleJustificationSwitchTab(tab: any) {
        if (tab) {
            const diagnostic = this.impreciseDiagnostics.find(impreciseDiagnostic => impreciseDiagnostic.diagnostic.slug === tab.name);
            if (diagnostic) {
                this._loadPreciseDiagnosticsAndShowJustification(diagnostic);
            } else {
                this.selectedImpreciseDiagnostic = null;
            }
        }
    }

    private _deleteImpreciseDiagnostic(diagnosticToDelete: any) {
        if (diagnosticToDelete) {
            this.impreciseDiagnostics = this.impreciseDiagnostics.filter(diagnosticObj => diagnosticObj.diagnostic.id !== diagnosticToDelete.id);
        }
    }

    private _filterDiagnostics() {
        if (this.diagnostics) {
            this.impreciseDiagnostics = this.diagnostics.filter(el => this._isImpreciseDiagnostic(el).isImprecise);
            this._broadcastService.send('stayDetailImpreciseDiagnostic::setImpreciseDiagnostics', {impreciseDiagnostics: this.impreciseDiagnostics});
        }
    }

    private _isImpreciseDiagnostic(diagnostic: any) {
        if (diagnostic) {
            const slugSplit = diagnostic.diagnostic && diagnostic.diagnostic.slug ? diagnostic.diagnostic.slug.split('.') : [];
            return {
                slugRoot: slugSplit[0] || '',
                isImprecise: slugSplit[1] === '9'
            };
        }
        return {slugRoot: '', isImprecise: false};
    }

    private _showJustification(diagnostic: any) {
        if (diagnostic) {
            let stay: any;
            if (this.dataSetElement) {
                stay = this.dataSetElement.dataSetContent;
            }
            this._broadcastService.send('dataSetElementStayJustification::addTab', {
                diagnostic: diagnostic.diagnostic,
                stay
            });
        }
    }

    private async _loadPreciseDiagnostics(diagnostic: any) {
        try {
            const slugRoot = this._isImpreciseDiagnostic(diagnostic).slugRoot;
            if (slugRoot) {
                const date = this.dataSetElement.dataSetContent.endDate || this.dataSetElement.dataSetContent.startDate;
                const res = await this._diagnosticService.loadDiagnosticsWithES$(`${slugRoot}.*`, false, date).toPromise();
                this.preciseDiagnostics = res.data
                    .map(el => ({diagnostic: el}))
                    .filter(el => !this._isImpreciseDiagnostic(el).isImprecise);
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private async _simulateDiagnosticsGrouping() {
        try {
            const codificationLabelId = [this.codificationLabels.DP, this.codificationLabels.DP_ROOT].includes(this.selectedImpreciseDiagnostic.codificationLabelId) ? this.codificationLabels.DP : this.codificationLabels.DA;
            const body = this._diagnosticService.buildSimulateGroupingBody(this.dataSetElement, this.preciseDiagnostics, codificationLabelId);
            const res = await this._diagnosticService
                .simulateGrouping(body);
            if (res) {
                this._diagnosticService.updateDiagnosticsPricingAndGhm(res, this.preciseDiagnostics, false);
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private async _loadPreciseDiagnosticsAndShowJustification(impreciseDiagnostic: any) {
        try {
            this.isLoading = true;
            this.selectedImpreciseDiagnostic = impreciseDiagnostic;
            this._showJustification(impreciseDiagnostic);
            await this._loadPreciseDiagnostics(impreciseDiagnostic);
            await this._simulateDiagnosticsGrouping();
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            console.error(e);
            throw e;
        }
    }

    clickOnImpreciseDiagnostic(impreciseDiagnostic: any) {
        if (this.selectedImpreciseDiagnostic &&
            this.selectedImpreciseDiagnostic.id === impreciseDiagnostic.id) {
            this.selectedImpreciseDiagnostic = null;
            this._broadcastService.send('stayDetailFilterSearch::reload', {isSelected: true, reloadOnlyFirstTab: true});
        } else {
            this._loadPreciseDiagnosticsAndShowJustification(impreciseDiagnostic);
        }
    }

    isSelected(diagnostic: any) {
        if (diagnostic &&
            diagnostic.diagnostic) {
            return this._stayDetailJustificationService.isTabActiveByName(diagnostic.diagnostic.slug);
        }
        return false;
    }

    orderDiagnostics(dataToOrder: any[]) {
        return _.orderBy(dataToOrder, ['diagnostic.slug'], ['asc']);
    }

    hasDiagnosticNotes(diagnostic: any) {
        return !!(diagnostic &&
            diagnostic.diagnostic &&
            diagnostic.diagnostic.diagnosticNote &&
            diagnostic.diagnostic.diagnosticNote.length > 0);
    }

    openDiagnosticNoteDisplayDialog(diagnostic: any) {
        if (diagnostic &&
            diagnostic.diagnostic &&
            this.hasDiagnosticNotes(diagnostic)) {
            this._diagnosticNoteService
                .openDiagnosticNoteDisplayDialog(diagnostic.diagnostic.id);
        }
    }

    getDiagnosticTooltip(diagnostic: any): string {
        if (diagnostic &&
            diagnostic.diagnostic) {
            const displaySeverityLevel = diagnostic.codificationLabel && diagnostic.codificationLabel.id === 3;
            return `${diagnostic.diagnostic.name || diagnostic.diagnostic.description}${this.canDisplaySeverityLevel && displaySeverityLevel && diagnostic.diagnostic.diagnosticLevel && diagnostic.diagnostic.diagnosticLevel.level ? ` (${this._translateService.instant('STUFF.LEVEL')}. ${diagnostic.diagnostic.diagnosticLevel.level})` : ''}`;
        }
        return '';
    }

    getDiagnosticLabelColor(diagnostic: any, suffix?: string): string {
        const stringToReturn = `md-${this._stayDetailCodificationDiagnosticService.getDiagnosticLabelColor(diagnostic, false, this.canDisplaySeverityLevel)}`;
        return !suffix ? stringToReturn : `${stringToReturn}-${suffix}`;
    }

    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}

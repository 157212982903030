<div class="flex-column-full-height">
    <mat-toolbar class="dialog-toolbar">
        <h2 class="title">{{'STUFF.DOCUMENTS' | translate}}</h2>
        <button mat-icon-button
                mat-dialog-close>
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </mat-toolbar>

    <div class="row flex-grow-1">
        <div class="col-3 no-padding-right">
            <ct-stay-detail-document-list [documents]="documents"
                                          [maxHeight]="'90vh'"
                                          [applyPaddingTop]="true"
                                          (clickOnDocumentName)="openDocument($event)"></ct-stay-detail-document-list>
        </div>

        <mat-divider class="width-auto no-padding"
                     [vertical]="true"></mat-divider>

        <div class="col no-padding">
            <ct-file-display *ngIf="displayDocumentData.src"
                             [data]="displayDocumentData"
                             [containerHeight]="'90vh'"
                             [objectHeight]="'100%'"></ct-file-display>

            <div class="align-center-center no-data full-height"
                 *ngIf="!displayDocumentData.src">
                {{'QUALITY_CONTROL.PLEASE_SELECT_DOCUMENT' | translate}}
            </div>
        </div>
    </div>
</div>

import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BroadcastService} from '../../../../../../core/services/broadcast.service';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {AppLogService} from '../../../../../../core/app-log/app-log.service';
import {StayDetailDiagnosticPredictionElementComponent} from '../stay-detail-diagnostic-prediction-element.component';
import {DiagnosticService} from '../../../../../diagnostic/diagnostic.service';
import {TranslationHelperService} from '../../../../../../core/services/translation.helper.service';
import {DataSetElementService} from '../../../../shared/data-set-element.service';
import {CodificationLabelsModel} from "../../../../shared/codification-labels.model";

export interface displayLabel {
    name: string,
    id: number
}

@Component({
    selector: 'ct-stay-detail-diagnostic-prediction-element-tooltip',
    templateUrl: './stay-detail-diagnostic-prediction-element-tooltip.component.html',
    styleUrls: ['./stay-detail-diagnostic-prediction-element-tooltip.component.scss']
})
export class StayDetailDiagnosticPredictionElementTooltipComponent implements OnInit {
    private _canDisplayImpreciseStayDateIndicatorFromConfig: boolean;

    codificationLabels: CodificationLabelsModel;
    labels: any[] = [];
    dataSetElement: any;
    predictiveDiagnostic: any;
    referencialPrice: number;
    predictionElementComponent: StayDetailDiagnosticPredictionElementComponent;
    user: any;
    canUpdateGold = false;
    impreciseDiagnostic: any;
    filterSearch: any;
    isTypeRehabilitation: boolean;

    constructor(private _translateService: TranslateService,
                private _translationHelperService: TranslationHelperService,
                private _broadcastService: BroadcastService,
                private _diagnosticService: DiagnosticService,
                private _dataSetElementService: DataSetElementService,
                private _configurationService: ConfigurationService,
                private _logService: AppLogService) {
    }

    ngOnInit(): void {
        this.user = JSON.parse(localStorage.getItem('user')) || {};
        this._initData();
        this._initConfig();
    }

    private _initData(): void {
        if (this.user?.roles) {
            const roles = this.user.roles.filter(role => ['admin', 'dim'].includes(role.name));
            this.canUpdateGold = roles.length > 0 && this.predictiveDiagnostic?.simplifiedScore === 40;
        }

        let labelsId = this.codificationLabels.getAppTypeMainLabels(this.isTypeRehabilitation);
        labelsId.forEach(labelId => {
            let displayLabel : displayLabel = {name: this.codificationLabels.getSlugFromId(labelId), id: labelId};
            this.labels.push(displayLabel);
        });
    }

    private _initConfig(): void {
        this._canDisplayImpreciseStayDateIndicatorFromConfig = this._configurationService.getConfigurationContent('front', 'date.canDisplayImpreciseStayDateIndicator');
    }

    private _getGoldScore(): number {
        if (this.predictiveDiagnostic?.score >= 0) {
            return this.predictiveDiagnostic.score + (this._configurationService.getConfigurationContent('front', 'diagnostic.goldIncreaseScore') || 0.001);
        }
        return 0.001;
    }

    async updateGold(): Promise<void> {
        try {
            if (this.predictiveDiagnostic?.diagnostic) {
                const codificationLabel = this._translationHelperService.getCodificationLabels()[this.predictiveDiagnostic?.codificationLabel.slug];
                this.predictiveDiagnostic.diagnostic.isLoading = true;
                await this._diagnosticService.updateDiagnosticGoldThreshold(this.predictiveDiagnostic.diagnostic.id, codificationLabel, this._getGoldScore());
                this.canUpdateGold = false;
                this._broadcastService.send('stay::updateGold', {reload: true});
                this._broadcastService.send('tooltipDirective::destroy');
                this.predictiveDiagnostic.diagnostic.isLoading = false;
            }
        } catch (e) {
            this.predictiveDiagnostic.diagnostic.isLoading = false;
            console.error(e);
            throw e;
        }
    }

    canDisplayImpreciseStayDateIndicator(): boolean {
        return this._canDisplayImpreciseStayDateIndicatorFromConfig && this._dataSetElementService.hasImpreciseDate(this.dataSetElement);
    }

    getMatTooltip(label: any): string {
        if (label) {
            return `${this._translateService
                .instant('DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.ADD_DIAGNOSTIC')} ${label.name}`;
        }
        return '';
    }

    getClasses(label: any): string {
        if (label?.id === this.codificationLabels.DA) {
            return this.predictionElementComponent
                .getDiagnosticLabelColor(this.predictiveDiagnostic, true, true) + '-100';
        } else if (label?.color?.name) {
            return `md-${label.color.name}-100`;
        }
        return '';
    }

    replaceByPreciseDiagnostic(): void {
        if (this.predictiveDiagnostic &&
            this.impreciseDiagnostic) {
            const data = {
                diagnosticToAdd: this.predictiveDiagnostic.diagnostic,
                diagnosticToRemove: this.impreciseDiagnostic.diagnostic,
                codificationLabelId: this.impreciseDiagnostic.codificationLabelId,
                referencialPrice: this.referencialPrice,
            };
            this._broadcastService.send('diagnostic::replaceImpreciseDiagnostic', data);
        }
    }

    updateCodification(label: any): void {
        if (this.predictiveDiagnostic && label) {
            const data = {
                diagnostic: this.predictiveDiagnostic.diagnostic,
                codificationLabelId: label.id,
                isPrediction: true,
                referencialPrice: this.referencialPrice,
                filterSearchId: this.filterSearch?.id
            };
            this._broadcastService.send('diagnostic::updateCodification', data);
        }
    }

    showJustification(): void {
        if (this.predictiveDiagnostic) {
            let stay: any;
            if (this.dataSetElement) {
                stay = this.dataSetElement.dataSetContent;
            }
            this._broadcastService.send('dataSetElementStayJustification::addTab', {
                diagnostic: this.predictiveDiagnostic.diagnostic,
                stay
            });
            this._logService.logInfo('Looking at prediction justification');
        }
    }
}

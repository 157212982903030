import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {FilterSearchApiService} from '../../../core/api-services/filter-search/filter-search.api.service';
import {DataHelperService} from '../../../core/services/data.helper.service';
import {ConfigurationService} from '../../configuration/configuration.service';
import {DataSetDataSetElementApiService} from '../../../core/api-services/data-set/data-set-element/data-set-data-set-element.api.service';

@Injectable({
    providedIn: 'root'
})
export class QualityControlService {
    private readonly _DATE_SORT_TYPE: string;

    constructor(private _filterSearchApiService: FilterSearchApiService,
                private _dataSetDataSetElementApiService: DataSetDataSetElementApiService,
                private _configurationService: ConfigurationService) {
        this._DATE_SORT_TYPE = this._configurationService.getConfigurationContent('front', 'advancedSearch.stayResultsDateSortType') || 'endDate';
    }

    async loadFilterSearchDataSetElements(params: any) {
        try {
            params = {
                ...params,
                include: ['subStays', 'diagnostics', 'establishment', 'user']
            };
            params.sort = `-${this._DATE_SORT_TYPE}`;
            return await this._filterSearchApiService
                .qualityControl(params)
                .pipe(map(res => DataHelperService.addInnerHitsToData(res)),
                    map(res => {
                        return {
                            ...res,
                            data: DataHelperService.buildDataSetElements(res.data)
                        };
                    }))
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async loadNearAutomationData(params: any) {
        try {
            const include = [
                'subStays',
                'diagnostics',
                'diagnosticPredictions',
                'acts',
                'documents',
                'user'];
            params = {
                ...params,
                include
            };
            params.sort = `-${this._DATE_SORT_TYPE}`;
            return await this._dataSetDataSetElementApiService.nearAutomation(params)
                .pipe(map(
                    res => {
                        return {
                            ...res,
                            data: DataHelperService.buildDataSetElements(res.data)
                        };
                    }))
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    getParamsForImpreciseDiagnosticQualityControlModule(params: any): any {
        if (params) {
            return {...params};
        }
        return {};
    }

    getParamsForNearAutomationQualityControlModule(params: any): any {
        if (params) {
            const paramsToReturn: any = {...params};
            delete paramsToReturn.stayLength;
            return paramsToReturn;
        }
        return {};
    }
}

import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionQueryStructuredComponent} from './search-engine-condition-query-structured.component';
import {DocumentTypeListService} from '../../../document-type/list/document-type-list.service';
import {map} from 'rxjs/operators';
import * as _ from 'lodash-es';
import {ApiCriteria, ComparisonArgs, SearchEngineCondition} from '../search-engine.condition';
import {DateHelperService} from '../../../../core/services/date.helper.service';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionQueryStructuredService extends SearchEngineCondition {

    constructor(private _translateService: TranslateService,
                private _documentTypeListService: DocumentTypeListService) {
        super([],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.VARIOUS'),
            'structuredData',
            SearchEngineConditionQueryStructuredComponent,
            true);
        this.init();
    }

    async init(uriParams?: any, apiParams?: ApiCriteria): Promise<void> {
        this.params = {
            structuredData: [{
                qStructuredDocumentTypeId: '',
                qStructuredDocumentTypeColumn: [],
                columns: []
            }]
        };
        await this.setApiParams(apiParams);
    }

    /**
     * Return proper column object param according to uri column type
     * @param data
     * @param columns
     * @private
     */
    private _getDocumentTypeColumnData(data: any, columns: any[]): any {
        const result = {
            q: '',
            dataType: [],
            operator: '',
            startDate : null,
            endDate: null,
            min: null,
            max: null,
            columnId: data.columnId,
            columnType: ''
        };
        const columnInfo = columns.find(column => column.id === data.columnId);
        if (columnInfo) {
            result.columnType = columnInfo.dataType;
            result.dataType = columnInfo.dataType;
            switch (columnInfo.dataType) {
                case 'string':
                    result.q = data.value;
                    break;
                case 'numeric':
                    result.min = data.value.minValue;
                    result.max = data.value.maxValue;
                    result.operator = data.value.operator;
                    break;
                case 'date':
                    if (data.value.operator === '>=') {
                        result.startDate = new Date(DateHelperService.mysqlToInput(data.value.minValue));
                    } else if (data.value.operator === '<=') {
                        result.endDate = new Date(DateHelperService.mysqlToInput(data.value.minValue));
                    } else {
                        result.startDate = new Date(DateHelperService.mysqlToInput(data.value.minValue));
                        result.endDate = new Date(DateHelperService.mysqlToInput(data.value.maxValue));
                    }
                    break;
            }
        }
        return result;
    }

    /**
     * Get column fields according to document type
     * @param documentTypeId
     * @param columnId
     * @private
     */
    private _loadColumn(documentTypeId: number, columnId: number): Promise<any> {
        return this._documentTypeListService
            .loadColumns$(documentTypeId)
            .pipe(map(columns => {
                return {
                    documentTypeId,
                    columnId,
                    columns
                };
            }))
            .toPromise();
    }

    /**
     * Set this.params according to result.tmpParams
     * @param promises
     * @param tmpParams
     * @private
     */
    private async _applyParams(promises: any[], tmpParams: any[]): Promise<void> {
        try {
            const columnsFromApi = await Promise.all(promises);
            tmpParams?.forEach(tmpParam => {
                const columnFromApi = columnsFromApi
                    .find(obj => obj.documentTypeId === tmpParam.documentTypeId);
                if (columnFromApi) {
                    tmpParam.columns = columnFromApi.columns;
                }
            });

            // Empty this.params because of the initialization in init()
            this.params.structuredData = [];
            tmpParams?.forEach(tmpParam => {
                const tmpDocumentParams = {
                    qStructuredDocumentTypeId: tmpParam.documentTypeId,
                    columns: tmpParam.columns,
                    qStructuredDocumentTypeColumn: []
                };
                tmpDocumentParams.qStructuredDocumentTypeColumn
                    .push(this._getDocumentTypeColumnData(tmpParam.columnData, tmpParam.columns));
                this.params.structuredData.push(tmpDocumentParams);
            });
        } catch (e) {
            throw e;
        }
    }

    private _processApiParam(value: any,
                             tmpParams: any[],
                             tmpDocumentTypeIds: any[]): {promise: Promise<any>, tmpParams: any[]} {
        let promise = null;
        const documentTypeId = value?.documentTypeId;
        value?.columns?.forEach(column => {
            if (!tmpDocumentTypeIds.includes(documentTypeId)) {
                tmpParams.push({documentTypeId, columnData: column});
                promise = this._loadColumn(documentTypeId, column.columnId);
                tmpDocumentTypeIds.push(documentTypeId);
            } else {
                const tmpParam = tmpParams.find(param => _.isEqual(param.columnData, column));
                if (!tmpParam) {
                    tmpParams.push({documentTypeId, columnData: column});
                }
            }
        });
        return {promise, tmpParams};
    }

    async convertToParamsFromApiParams(): Promise<void> {
        try {
            let result: any = {};
            const tmpParams = [];
            const tmpDocumentTypeIds = [];
            const promises = [];
            this.apiParams.args.values?.forEach(value => {
                result = this._processApiParam(value, tmpParams, tmpDocumentTypeIds);
                if (result.promise) {
                    promises.push(result.promise);
                }
            });
            await this._applyParams(promises, result.tmpParams);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Build an uri string according to params
     * @param column
     * @private
     */
    private _extractParams(column: any): string {
        let columnData = column.columnId + ':';
        if ('string' === column.columnType && column.q) {
            columnData += column.q.replace(/"/g, '\\"');
        } else if ('numeric' === column.columnType &&
            (column.min !== '' || column.max !== '')) {
            columnData += column.min;
            if (('><' === column.operator || '>=<' === column.operator) && column.max !== '') {
                columnData += '$' + column.operator + '$' + column.max;
            } else if (('><' !== column.operator && '>=<' !== column.operator) || column.max === '') {
                columnData += '$' + column.operator;
            }
        } else if ('date' === column.columnType &&
            (column.startDate || column.endDate)) {
            if (column.startDate &&
                column.endDate) {
                columnData += DateHelperService.toMysql(column.startDate) + '$>=<$' + DateHelperService.toMysql(column.endDate);
            } else if (column.startDate) {
                columnData += DateHelperService.toMysql(column.startDate) + '$>=';
            } else if (column.endDate) {
                columnData += DateHelperService.toMysql(column.endDate) + '$<=';
            }
        } else {
            columnData = null;
        }
        return columnData;
    }

    private _getComparisonOject(value: string): ComparisonArgs {
        if (value) {
            const valueSplit = value.split('$');
            const objToReturn: ComparisonArgs = {minValue: Number.parseFloat(valueSplit[0]), operator: valueSplit[1]};
            if (valueSplit.length === 3) {
                objToReturn.maxValue = Number.parseFloat(valueSplit[2]);
            }
            return objToReturn;
        }
        return {minValue: '', operator: ''};
    }

    private _getValueForApiParams(columnType: string, value: string): string | ComparisonArgs {
        switch (columnType) {
            case 'string':
                return value;
            case 'numeric':
                return this._getComparisonOject(value);
            case 'date':
                return this._getComparisonOject(value);
        }
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        const values = [];
        const columns = [];
        this.params.structuredData.forEach(param => {
            if (param.qStructuredDocumentTypeId) {
                param.qStructuredDocumentTypeColumn.forEach(column => {
                    if (column.columnType && column.columnId) {
                        const columnData = this._extractParams(column);
                        if (columnData) {
                            const value = columnData.split(':')[1];
                            columns.push({columnId: column.columnId, value: this._getValueForApiParams(column.columnType, value), dataType: column.dataType});
                        }
                    }
                });
                values.push({
                    documentTypeId: param.qStructuredDocumentTypeId,
                    columns
                });
            }
        });
        this.apiParams.args = {values};
    }

    isValidApiParams(args: any): boolean {
        return !!args?.values?.length;
    }
}

import {Injectable} from '@angular/core';
import {DiagnosticApiService} from '../../core/api-services/diagnostic/diagnostic.api.service';
import {CodificationGroupLabelCodificationLabelApiService} from '../../core/api-services/codification-group-label/codification-label/codification-group-label-codification-label.api.service';
import {ConfigurationService} from '../configuration/configuration.service';
import * as moment from 'moment';
import {DateHelperService} from '../../core/services/date.helper.service';
import {TranslationHelperService} from '../../core/services/translation.helper.service';
import {GroupingFunctionApiService} from '../../core/api-services/grouping-function/grouping-function.api.service';
import {DiagnosisChronicApiService} from '../../core/api-services/diagnosis-chronic/diagnosis-chronic.api.service';
import {MachineLearningScopeApiService} from '../../core/api-services/machine-learning/scope/machine-learning-scope.api.service';
import {DataSetElement} from '../stay/shared/data-set-element.model';
import {CodificationLabelsModel} from '../stay/shared/codification-labels.model';

@Injectable({
    providedIn: 'root'
})
export class DiagnosticService {
    public labels: CodificationLabelsModel;
    private _codificationLabelsByAppType: any = {
        MCO: [],
        SSR: []
    };

    constructor(private _diagnosticApiService: DiagnosticApiService,
                private _groupingFunctionApiService: GroupingFunctionApiService,
                private _machineLearningScopeApiService: MachineLearningScopeApiService,
                private _diagnosisChronic: DiagnosisChronicApiService,
                private _translationHelperService: TranslationHelperService,
                private _codificationGroupLabelCodificationLabelApiService: CodificationGroupLabelCodificationLabelApiService,
                private _configurationService: ConfigurationService,
                private _dateHelperService: DateHelperService) {
        this._initLanguage();
    }

    private _initLanguage() {
        this._codificationLabelsByAppType.MCO = this._translationHelperService.getCodificationLabelsByAppType('MCO');
        this._codificationLabelsByAppType.SSR = this._translationHelperService.getCodificationLabelsByAppType('SSR');
    }

    private _getParamsForLoadDiagnostics(params: any) {
        let newParams = {perPage: 1000};
        if (params) {
            newParams = {...newParams, ...params};
        }
        return newParams;
    }

    loadDiagnostics(params: any = {}) {
        try {
            params = this._getParamsForLoadDiagnostics(params);
            return this._diagnosticApiService
                .getAll(params)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    filterByYearOfValidity(dataSetElement: DataSetElement,
                           diagnostics: any[],
                           isDiagnosticObj = true) {
        if (dataSetElement &&
            diagnostics) {
            const date = dataSetElement.dataSetContent.endDate || dataSetElement.dataSetContent.startDate;
            return diagnostics.filter(el => {
                const elementValidFrom = isDiagnosticObj ? el.validFrom : el.diagnostic.validFrom;
                const elementValidTo = isDiagnosticObj ? el.validTo : el.diagnostic.validTo;
                return (elementValidFrom === null && elementValidTo === null) ||
                    ((elementValidFrom === null || elementValidFrom <= date) && (elementValidTo === null || elementValidTo >= date));
            });
        }
        return [];
    }

    async loadChronicDiagnostics(dataSetElement: DataSetElement) {
        try {
            const diagnostics = await this._diagnosisChronic
                .getAll()
                .toPromise();
            return this.filterByYearOfValidity(dataSetElement, diagnostics);
        } catch (e) {
            throw e;
        }
    }

    loadDiagnosticsWithES$(query: string, withSlugParam: boolean = false, date?: string) {
        let params: any = {[!withSlugParam ? 'q' : 'slug']: query};
        if (date) {
            params.validFrom = `${date.substr(0, 10)};<=`;
            params.validTo = `${date.substr(0, 10)};>=`;
        }
        params = this._getParamsForLoadDiagnostics(params);
        return this._diagnosticApiService
            .getAllWithES(params);
    }

    async loadGoldDiagnostics(params: any = {}) {
        try {
            params = this._getParamsForLoadDiagnostics(params);
            return await this._diagnosticApiService
                .getGolds(params)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async loadAllCodificationLabels(codificationGroupLabelId: number, isTypeRehabilitation: boolean) {
        try {
            const params = {
                fields: 'color',
                perPage: 50
            };

            this.labels = await this._codificationGroupLabelCodificationLabelApiService
                .getAll(codificationGroupLabelId, params)
                .toPromise().then((response: any): CodificationLabelsModel => {
                    const labels: CodificationLabelsModel = new CodificationLabelsModel();

                    response.forEach(codificationLabel => {
                        labels[codificationLabel.slug] = codificationLabel.id;
                    });
                    return labels;
                });
            return this.labels;
        } catch (e) {
            throw e;
        }
    }

    async loadPotentialGolds(rangeOfActivity: number, params?: any) {
        try {
            params = params || {};
            params['minScore'] = params['minScore'] || 0.99;
            params['minOccurrence'] = params['minOccurrence'] || 10;
            params['since'] = params['since'] || DateHelperService.toMysql(moment().subtract(rangeOfActivity, 'months').toDate());
            params['fields'] = 'diagnosticLevel';
            return await this._diagnosticApiService
                .getPotentialGolds(params)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async updateDiagnostic(diagnosticId:  number, body: any) {
        try {
            return await this._diagnosticApiService
                .update(diagnosticId, body)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    updateDiagnosticsPricingAndGhm(data: any[],
                                   predictions: any[],
                                   checkCodificationLabelId: boolean = true): void {
        if (data &&
            predictions) {
            data.forEach(obj => {
                const diagnostic = predictions.find(el => el.diagnostic.id === obj.diagnosticId &&
                    (!checkCodificationLabelId || el.diagnostic.codificationLabelId === obj.codificationLabelId));
                if (diagnostic) {
                    diagnostic.healthGhsGhm = {healthGhmSlug: obj.ghm};
                    diagnostic.pricing = obj.price;
                }
            });
        }
    }

    buildSimulateGroupingBody(dataSetElement: DataSetElement,
                              diagnostics: any[],
                              codificationLabelId?: number): any {
        const body = {
            dataSetElementId: dataSetElement?.id,
            type: dataSetElement?.dataSetContent.type,
            predictions: []
        };
        if (diagnostics) {
            body.predictions = diagnostics.reduce((accumulator, currentValue) => {
                if (codificationLabelId ||
                    currentValue.codificationLabelId) {
                    accumulator.push({
                        diagnosticId: currentValue.diagnostic?.id,
                        codificationLabelId: codificationLabelId ?? currentValue.codificationLabelId
                    });
                }
                return accumulator;
            }, []);
        }
        return body;
    }

    async simulateGrouping(body: any) {
        try {
            return await this._groupingFunctionApiService
                .simulate(body)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async updateDiagnosticGoldThreshold(diagnosticId:  number, codificationLabel = 'DP', threshold: number) {
        try {
            const body = {
                codificationLabel,
                customGold : threshold,
                isAutoGold : true
            };
            return await this._machineLearningScopeApiService
                .updateDiagnosticGoldThreshold(diagnosticId, body)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}

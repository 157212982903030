<div class="display-inline-block margin-right-20">
    <mat-form-field>
        <input matInput
               type="number"
               step="any"
               min="0"
               [max]="condition.service.params[paramMaxKey]"
               [(ngModel)]="condition.service.params[paramMinKey]"
               [placeholder]="'DATA_SET.CODIFICATION.SEARCH.MINIMUM' | translate">
    </mat-form-field>
    <span *ngIf="unit">{{unit}}</span>
</div>

<mat-form-field>
    <input matInput
           type="number"
           step="any"
           max="100000"
           [min]="condition.service.params[paramMinKey]"
           [(ngModel)]="condition.service.params[paramMaxKey]"
           [placeholder]="'DATA_SET.CODIFICATION.SEARCH.MAXIMUM' | translate">
</mat-form-field>
<span *ngIf="unit">{{unit}}</span>

<div class="document-list"
     *ngIf="isActive"
     [ngClass]="{'padding-top-10': applyPaddingTop}"
     [ngStyle]="{'max-height': maxHeight}">
    <div *ngIf="hasDocuments('stay')">
        <h3
            *ngIf="canDisplayStayRisk && !forNearAutomationList"
            class="h3 documents-title"
        >
            {{ 'STUFF.DOCUMENTS_STAY' | translate }}
        </h3>

        <ng-container *ngFor="let inferredDocumentTypeName of inferredDocumentTypes; index as i; last as isLastInferredDocumentType">
            <div *ngIf="stayDocuments[inferredDocumentTypeName]"
                 [ngClass]="{'margin-bottom-10': !isLastInferredDocumentType}">
                <div class="margin-bottom-10"
                     *ngFor="let documentTypeName of getObjectKeys(stayDocuments[inferredDocumentTypeName]); index as j; last as isLastDocumentType">
                    <span class="badge rounded-pill bg-secondary margin-right-10">{{getInferredDocumentTypeDisplayText(inferredDocumentTypeName)}}</span>
                    <span class="document-type"><b>{{documentTypeName}}</b></span>

                    <ct-document-type-document-list-display-button
                        *ngIf="forNearAutomationList"
                        [documents]="documents"
                        [inferredDocumentTypeName]="inferredDocumentTypeName"
                        [documentTypeName]="documentTypeName"
                    ></ct-document-type-document-list-display-button>

                    <ng-container *ngIf="!forNearAutomationList">
                        <ng-container *ngFor="let document of stayDocuments[inferredDocumentTypeName][documentTypeName]; index as k; last as isLastDocument">
                            <div class="document"
                                 *ngIf="document.subDocumentId !== false"
                                 [ngClass]="{'margin-bottom-10': !isLastDocumentType}"
                                 (click)="onClickOnDocumentName(document)">
                                <div class="file-name-container"
                                     (mouseover)="onMouseOverChange(i, j, k, true, 'stay')"
                                     (mouseout)="onMouseOverChange(i, j, k, false, 'stay')"
                                     [matTooltip]="displayType !== 'documentFileName' ? getFilename(stayDocuments[inferredDocumentTypeName][documentTypeName], k) : ''"
                                     [ngClass]="{'hover': isStayFileNameContainerHover[i] && isStayFileNameContainerHover[i][j] ? isStayFileNameContainerHover[i][j][k] : false,
                          'margin-bottom-10': (stayDocuments[inferredDocumentTypeName][documentTypeName].length > 1) && !isLastDocument}">
                                <span class="margin-right-16">
                                <ng-container *ngIf="displayType === 'documentFileName'">
                                    {{ document.filename }}
                                </ng-container>
                                <ng-container *ngIf="displayType !== 'documentFileName'">
                                    {{ documentTypeName }}
                                    ({{ stayDocuments[inferredDocumentTypeName][documentTypeName].length }})
                                </ng-container>
                            </span>
                                    <i class="fas fa-folder-open"
                                       *ngIf="document.isOpened"></i>
                                </div>
                                <mat-divider *ngIf="(stayDocuments[inferredDocumentTypeName][documentTypeName].length > 1) && !isLastDocument"></mat-divider>
                            </div>
                        </ng-container>
                    </ng-container>

                    <mat-divider *ngIf="!forNearAutomationList && getObjectKeys(stayDocuments[inferredDocumentTypeName]).length > 1 && !isLastDocumentType"></mat-divider>
                </div>

                <mat-divider *ngIf="!forNearAutomationList && !isLastInferredDocumentType && hasMoreDocuments('stay', i)"></mat-divider>
            </div>
        </ng-container>
    </div>

    <mat-divider *ngIf="hasDocuments('stay') && hasDocuments('patient')"></mat-divider>

    <div class="margin-top-10"
         *ngIf="hasDocuments('patient')">
        <h3 class="h3 documents-title"
            [matTooltip]="'TOOLTIP.DOCUMENTS_PATIENT' | translate">{{'STUFF.DOCUMENTS_PATIENT' | translate}}</h3>

        <ng-container *ngFor="let inferredDocumentType of inferredDocumentTypes; index as i; last as isLastInferredDocumentType">
            <div *ngIf="patientDocuments[inferredDocumentType]"
                 [ngClass]="{'margin-bottom-10': !isLastInferredDocumentType}">
                <div class="margin-bottom-10"
                     *ngFor="let documentTypeName of getObjectKeys(patientDocuments[inferredDocumentType]); index as j; last as isLastDocumentType">
                    <span class="badge rounded-pill bg-secondary margin-right-10">{{getInferredDocumentTypeDisplayText(inferredDocumentType)}}</span>
                    <span class="document-type"><b>{{documentTypeName}}</b></span>

                    <ct-document-type-document-list-display-button *ngIf="forNearAutomationList"
                                                                   [documents]="documents"></ct-document-type-document-list-display-button>

                    <ng-container *ngIf="!forNearAutomationList">
                        <div class="document"
                             *ngFor="let document of patientDocuments[inferredDocumentType][documentTypeName]; index as k; last as isLastDocument"
                             [ngClass]="{'margin-bottom-10': !isLastDocumentType}"
                             (click)="onClickOnDocumentName(document)">
                            <div class="file-name-container"
                                 (mouseover)="onMouseOverChange(i, j, k, true, 'stay')"
                                 (mouseout)="onMouseOverChange(i, j, k, false, 'stay')"
                                 [matTooltip]="displayType !== 'documentFileName' ? document.filename : ''"
                                 [ngClass]="{'hover': isPatientFileNameContainerHover[i] && isPatientFileNameContainerHover[i][j] ? isPatientFileNameContainerHover[i][j][k] : false,
                          'margin-bottom-10': (patientDocuments[inferredDocumentType][documentTypeName].length > 1) && !isLastDocument}">
                            <span class="margin-right-16">
                                <ng-container *ngIf="displayType === 'documentFileName'">{{document.filename}}</ng-container>
                                <ng-container *ngIf="displayType !== 'documentFileName'">{{documentTypeName}} {{k + 1}}</ng-container>
                            </span>
                                <i class="fas fa-folder-open"
                                   *ngIf="document.isOpened"></i>
                            </div>
                            <mat-divider *ngIf="(patientDocuments[inferredDocumentType][documentTypeName].length > 1) && !isLastDocument"></mat-divider>
                        </div>
                    </ng-container>

                    <mat-divider *ngIf="!forNearAutomationList && getObjectKeys(patientDocuments[inferredDocumentType]).length > 1 && !isLastDocumentType"></mat-divider>
                </div>

                <mat-divider *ngIf="!forNearAutomationList && !isLastInferredDocumentType && hasMoreDocuments('patient', i)"></mat-divider>
            </div>
        </ng-container>

        <div class="align-center-center margin-top-10 margin-bottom-10"
             *ngIf="!hasDocuments('patient')">
            <div class="no-data text-center">
                {{'ERROR.NO_PATIENT_DOCUMENT' | translate}}
            </div>
        </div>
    </div>

    <div class="align-center-center margin-top-10"
         *ngIf="!hasDocuments('stay') && !hasDocuments('patient')">
        <div class="no-data text-center">
            {{'ERROR.NO_DOCUMENT' | translate}}
        </div>
    </div>
</div>

<div class="align-center-center margin-top-20"
     *ngIf="!isActive">
    <mat-progress-spinner
        mode="indeterminate"
        [diameter]="100"></mat-progress-spinner>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {SimplifiedScoreEnum} from '../../../../diagnostic/diagnostic.model';

@Component({
    selector: 'ct-stay-detail-act-prediction-list',
    templateUrl: './stay-detail-act-prediction-list.component.html',
    styleUrls: ['./stay-detail-act-prediction-list.component.scss', '../../diagnostic-prediction/list/stay-detail-diagnostic-prediction-list.component.scss']
})
export class StayDetailActPredictionListComponent implements OnInit {

    @Input() predictiveActs: any[];
    constructor() {
    }

    ngOnInit(): void {
    }
    public canDisplayActPrediction() {
        if (this.predictiveActs && this.predictiveActs.length > 0) {
            this.predictiveActs.forEach((predictiveAct: any) => {
                if (predictiveAct.act && predictiveAct.act.id &&
                    [SimplifiedScoreEnum.GOLD, SimplifiedScoreEnum.SILVER, SimplifiedScoreEnum.SILVER2, SimplifiedScoreEnum.SILVER3].includes(predictiveAct.simplifiedScore)) {
                    return true;
                }
            });
        }
        return false;
    }
}

<div class="codification-block">
    <!-- Coded diagnostics -->
    <ng-container *ngIf="!isTypeRehabilitation">
        <ct-stay-detail-codification-diagnostic
            [dataSetElement]="dataSetElement"
            [dataSetCodification]="dataSetCodification"
            [predictiveDiagnostics]="predictiveDiagnostics"
            [codificationLabels]="codificationLabels"
            [diagnostics]="diagnostics"
            [disableDragAndDrop]="false"
        ></ct-stay-detail-codification-diagnostic>
    </ng-container>

    <ng-container *ngIf="isTypeRehabilitation">
        <ct-stay-detail-codification-diagnostic
            [dataSetElement]="dataSetElement"
            [dataSetCodification]="dataSetCodification"
            [predictiveDiagnostics]="predictiveDiagnostics"
            [codificationLabels]="codificationLabels"
            [diagnostics]="diagnostics"
            [limitCodificationUpdateToDA]="limitCodificationUpdateToDA"
            [disableDragAndDrop]="false"
        ></ct-stay-detail-codification-diagnostic>
    </ng-container>

    <ng-container *ngIf="canDisplayStayAct && isTypeRehabilitation">
        <mat-divider></mat-divider>

        <!-- Coded acts -->
        <ct-stay-detail-codification-act
            [dataSetElement]="dataSetElement"
            [validateButton]="true"
            [isSSRUseCase]="isTypeRehabilitation"
        ></ct-stay-detail-codification-act>
    </ng-container>
</div>

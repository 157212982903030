import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as _ from 'lodash-es';
import {DocumentTypeListService} from '../../../document-type/list/document-type-list.service';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {DocumentType} from '../../../document-type/document-type.model';
import {ColorService} from '../../../../core/services/color.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'ct-search-engine-condition-query',
    templateUrl: './search-engine-condition-query.component.html',
    styleUrls: ['./search-engine-condition-query.component.scss']
})
export class SearchEngineConditionQueryComponent implements OnInit, AfterViewInit, OnDestroy {
    private _subscriptions: Subscription[] = [];

    @ViewChild('multipleSelect') matSelect;

    condition: any;
    documentTypes: DocumentType[] = [];
    isLoading: boolean;

    constructor(public colorService: ColorService,
                private _documentTypeListService: DocumentTypeListService,
                private _broadcastService: BroadcastService,
                private _configurationService: ConfigurationService) { }

    ngAfterViewInit() {
        // To fix jumping scroll when select an option
        (<any> this.matSelect)._getItemHeight = () => this.matSelect._triggerFontSize * 1.85;
    }

    ngOnInit() {
        this.isLoading = true;
        this._documentTypeListService
            .loadAllDocumentTypes({indexer: true})
            .then(res => {
                let namedDocumentTypes = this._replaceDocumentTypesName(res.data);
                this.documentTypes = _.orderBy(namedDocumentTypes, [documentType => documentType.name.toLowerCase()], ['asc']);
                this.isLoading = false;
            })
            .catch(() => this.isLoading = false);
        // Because the ids from the service are strings and the ones from api are numbers
        this.condition.service.params.query = this.condition.service.params.query
            .map(obj => {
                return {
                    ...obj,
                    qDocumentTypeId: obj.qDocumentTypeId.map(id => Number.parseFloat(id))
                };
            });

        this._subscribeToBroadcast();
    }

    private _subscribeToBroadcast() {
        const broadcastSub = this._broadcastService.broadcastData
            .subscribe(res => {
                if (res &&
                    res.message === 'searchEngine::switchMustAndNot') {
                    this._switchMustAndNot();
                }
            });
        this._subscriptions.push(broadcastSub);
    }

    private _switchMustAndNot() {
        if (this.condition.service.params.query) {
            this.condition.service.params.query.forEach(param => {
                const qMust = param.qMust;
                param.qMust = param.qNot ? param.qNot : '';
                param.qNot = qMust ? qMust : '';
            });
        }
    }

    private _replaceDocumentTypesName(documentTypes: DocumentType[]) {
        if (documentTypes) {
            documentTypes.forEach(documentType => {
                if (!documentType.name) {
                    documentType.name = documentType.slug || '';
                }
            });
            return documentTypes;
        }
        return [];
    }

    search() {
        this._broadcastService.send('queryCondition::search');
    }

    trackByFn(index: number) {
        return index;
    }

    addQueryCondition() {
        this.condition.service.params.query.push({
            qMust: '',
            qNot: '',
            qOpt: '',
            q: '',
            qDocumentTypeId: [],
            ignoreNegated: true
        });
    }

    removeQueryCondition(index: number) {
        if (this.condition.service.params.query.length > 1) {
            this.condition.service.params.query.splice(index, 1);
        }
    }

    isIndeterminate(index: number) {
        return this.condition.service.params.query[index].qDocumentTypeId.length !== 0 &&
            !this.condition.service.params.query[index].qDocumentTypeId.includes(-1) &&
            !this.isChecked(index);
    }

    isChecked(index: number) {
        return this.condition.service.params.query[index].qDocumentTypeId.length === this.documentTypes.length;
    }

    selectionChange(ids: number[], index: number) {
        this.condition.service.params.query[index].qDocumentTypeId = ids;
    }

    toggleAll(index: number, event) {
        // To prevent the checkbox to be checked because when we update the ids
        // the isChecked method updates the checkbox checked attribute
        if (event) {
            event.preventDefault();
        }
        if (this.isChecked(index)) {
            this.condition.service.params.query[index].qDocumentTypeId = [];
        } else {
            this.condition.service.params.query[index].qDocumentTypeId = this.documentTypes
                .slice(0)
                .map(doc => doc.id);
        }
    }

    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}

import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionNumberComparisonComponent} from '../../shared/search-engine-condition-number-comparison/search-engine-condition-number-comparison.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionStayAgeService extends SearchEngineCondition {
    // Because of shared component
    paramMinKey = 'ageMin';
    paramMaxKey = 'ageMax';
    placeholder = 'HEALTH.PATIENT.AGE_STAY';

    constructor(private _translateService: TranslateService) {
        super([],
            _translateService.instant('HEALTH.PATIENT.AGE_STAY'),
            'stayAge',
            SearchEngineConditionNumberComparisonComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {ageMin: null, ageMax: null};
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.ageMin = this.apiParams.args.minValue;
        this.params.ageMax = this.apiParams.args.maxValue ?? null;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {...this.getComparisonArgsObject(this.params.ageMin, this.params.ageMax)};
    }

    isValidApiParams(args: any): boolean {
        return !!(args && !this.equalsFalse(args.minValue) && args.operator);
    }
}

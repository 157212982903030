import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DataSetElement} from '../../shared/data-set-element.model';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {Subscription} from 'rxjs';
import * as _ from 'lodash-es';
import {Diagnostic} from '../../../diagnostic/diagnostic.model';
import {DiagnosticService} from '../../../diagnostic/diagnostic.service';
import {CodificationLabelsModel} from "../../shared/codification-labels.model";

@Component({
    selector: 'ct-stay-detail-chronic-diagnostic',
    templateUrl: './stay-detail-chronic-diagnostic.component.html',
    styleUrls: ['./stay-detail-chronic-diagnostic.component.scss']
})
export class StayDetailChronicDiagnosticComponent implements OnInit, OnDestroy {
    private _subscriptions: Subscription[] = [];
    private _chronicDiagnostics: Diagnostic[] = [];

    @Input() dataSetElement: DataSetElement;
    @Input() diagnostics: any[];
    @Input() codificationLabels: CodificationLabelsModel;
    @Input() isTypeRehabilitation: boolean;

    isLoading = false;
    isPatientStayHistoryLoading = true;
    isGroupingSimulationLoading = true;
    diagnosticHistory: any[] = [];
    matchingChronicDiagnostics: any[] = [];
    canDisplayAllDiagnostics: boolean;

    constructor(private _broadcastService: BroadcastService,
                private _diagnosticService: DiagnosticService) {
    }

    ngOnInit() {
        this._loadChronicDiagnostics();
        this._subscribeToBroadcast();
    }

    private _subscribeToBroadcast() {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'stayDetailInfo::loadPatientStayHistoryDone':
                        if (res.data &&
                            res.data.diagnosticHistory) {
                            this._mapDiagnosticHistory(res.data.diagnosticHistory);
                            if (this._chronicDiagnostics &&
                                this._chronicDiagnostics.length > 0 &&
                                this.matchingChronicDiagnostics &&
                                this.matchingChronicDiagnostics.length === 0) {
                                this._mapMatchingChronicDiagnostics();
                            }
                            this.isPatientStayHistoryLoading = false;
                        }
                        break;
                }
            });
        this._subscriptions.push(sub);
    }

    private async _simulateDiagnosticsGrouping() {
        try {
            this.isGroupingSimulationLoading = true;
            const body = this._diagnosticService.buildSimulateGroupingBody(this.dataSetElement, this.diagnosticHistory, this.codificationLabels.DA);
            if (body && body.predictions && body.predictions.length > 0) {
                const res = await this._diagnosticService
                    .simulateGrouping(body);
                if (res) {
                    this._diagnosticService.updateDiagnosticsPricingAndGhm(res, this.diagnosticHistory, false);
                }
            }
            this.isGroupingSimulationLoading = false;
        } catch (e) {
            this.isGroupingSimulationLoading = false;
            console.error(e);
            throw e;
        }
    }

    private async _loadChronicDiagnostics() {
        try {
            this.isLoading = true;
            this._chronicDiagnostics = await this._diagnosticService
                .loadChronicDiagnostics(this.dataSetElement);
            if (this.diagnosticHistory &&
                this.diagnosticHistory.length > 0 &&
                this.matchingChronicDiagnostics &&
                this.matchingChronicDiagnostics.length === 0) {
                this._mapMatchingChronicDiagnostics();
            }
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            console.error(e);
            throw e;
        }
    }

    /**
     * Set this.matchingChronicDiagnostics which are the diagnostics that are in the chronic diagnostic list and
     * also in the patient medical history
     * @private
     */
    private _mapMatchingChronicDiagnostics() {
        if (this.diagnosticHistory &&
            this._chronicDiagnostics) {
            // Intersection between all the chronic diagnostics and the diagnostic history
            this.matchingChronicDiagnostics = this.diagnosticHistory
                .filter(diagnostic => this._chronicDiagnostics.some(chronicDiagnostic => diagnostic.diagnostic.id === chronicDiagnostic.id));
        }
    }

    private _mapDiagnosticHistory(diagnosticHistory: any) {
        if (diagnosticHistory) {
            const history: any[] = Object.values(diagnosticHistory).flat();
            // Remove duplicates and map to match prediction element component
            this.diagnosticHistory = history
                .filter((obj,
                         position,
                         array) => array.map(diagnostic => diagnostic.id).indexOf(obj.id) === position)
                .map(diagnostic => ({diagnostic}));
            this._simulateDiagnosticsGrouping();
        }
    }

    orderDiagnosticsToDisplay() {
        const arrayToOrder = !this.canDisplayAllDiagnostics ? this.matchingChronicDiagnostics : this.diagnosticHistory;
        return _.orderBy(arrayToOrder, ['diagnostic.slug'], ['asc']);
    }

    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }

    protected readonly Number = Number;
}
